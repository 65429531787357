import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body p-10 p-lg-15" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Overview = _resolveComponent("Overview")
  const _component_CustomFormControl = _resolveComponent("CustomFormControl")
  const _component_CustomFormSelect = _resolveComponent("CustomFormSelect")
  const _component_CustomCheckboxAndRadio = _resolveComponent("CustomCheckboxAndRadio")
  const _component_CustomSwitch = _resolveComponent("CustomSwitch")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_Overview),
      _createVNode(_component_CustomFormControl),
      _createVNode(_component_CustomFormSelect),
      _createVNode(_component_CustomCheckboxAndRadio),
      _createVNode(_component_CustomSwitch)
    ])
  ]))
}